<template>
  <div>
    <ValidationObserver :ref="refKey">
      <h2 class="mt-3">기본 정보</h2>
      <v-row dense>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              v-model="user.uid"
              label="아이디"
              outlined
              :error-messages="errors"
              required
              dense
              readonly
              disabled
              filled
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required|email">
            <v-text-field
              v-model="user.email"
              label="이메일 주소"
              outlined
              type="email"
              :error-messages="errors"
              required
              dense
              readonly
              disabled
              filled
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="min:8">
            <v-text-field
              v-model="user.password"
              type="password"
              label="비밀번호 변경"
              outlined
              autocomplete="new-password"
              :error-messages="errors"
              required
              ref="password"
              dense
              counter
              :filled="!editable"
              :disabled="!editable"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" lg="6">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{
              required: isPasswordConfirmRequired,
              confirmed: user.password
            }"
          >
            <v-text-field
              v-model="user.password_confirm"
              type="password"
              label="비밀번호 변경 확인"
              outlined
              :error-messages="errors"
              required
              dense
              counter
              :filled="!editable"
              :disabled="!editable"
            />
          </ValidationProvider>
        </v-col>
      </v-row>

      <h2 class="mt-3">
        사업자 정보
        <small class="red--text"
          >사업자등록증과 동일한 사업자 정보를 입력해주세요.</small
        >
      </h2>
      <v-row dense>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              v-model="user.businessName"
              label="사업자 상호"
              outlined
              :error-messages="errors"
              required
              dense
              :filled="!editable"
              :disabled="!editable"
            />
          </ValidationProvider>
        </v-col>
        <!-- <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              v-model="user.businessType"
              label="사업자 유형"
              outlined
              :error-messages="errors"
              required
              dense
              :filled="!editable"
              :disabled="!editable"
            ></v-text-field>
          </ValidationProvider>
        </v-col> -->
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              v-model="user.managerName"
              label="담당자 명"
              outlined
              :error-messages="errors"
              required
              dense
              :filled="!editable"
              :disabled="!editable"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" lg="6">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|numeric|min:10|max:11"
          >
            <v-text-field
              v-model="user.managerTel"
              type="tel"
              label="담당자 연락처"
              outlined
              :error-messages="errors"
              required
              persistent-hint
              hint="- 없이 번호만 입력하세요."
              counter="11"
              maxlength="11"
              dense
              :filled="!editable"
              :disabled="!editable"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required|digits:10">
            <v-text-field
              v-model.number="user.businessNum"
              type="tel"
              label="사업자 등록번호"
              outlined
              :error-messages="errors"
              required
              persistent-hint
              hint=" (ex ●●●-●●-●●●●●) - 없이 번호만 입력하세요.)"
              counter="10"
              dense
              maxlength="10"
              :filled="!editable"
              :disabled="!editable"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12">
          <h3>사업자 주소</h3>
          <KakaoAddressForm
            v-if="kakaoAddress"
            popupKey="user"
            business
            popupTitle="정보수정 - 사업자 주소 검색"
            :dense="true"
            :disabled="!editable"
            :address="kakaoAddress"
            @submit="
              value => {
                user.businessPostcode = value.postcode;
                user.businessAddress = value.address;
                user.businessDetailAddress = value.detailAddress;
                user.businessExtraAddress = value.extraAddress;
              }
            "
          />
        </v-col>
      </v-row>

      <h2 class="mt-3">
        결제 정보
        <small class="red--text">통장 사본과 동일한 정보를 입력해주세요.</small>
      </h2>
      <v-row dense>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-autocomplete
              v-model="user.bankName"
              label="은행명"
              outlined
              :items="BankList"
              :error-messages="errors"
              required
              dense
              hide-no-data
              :filled="!editable"
              :disabled="!editable"
            ></v-autocomplete>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required|numeric">
            <v-text-field
              v-model="user.bankAccountNum"
              label="계좌번호"
              outlined
              :error-messages="errors"
              required
              dense
              :filled="!editable"
              :disabled="!editable"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              v-model="user.bankAccountHolder"
              label="예금주"
              outlined
              :error-messages="errors"
              required
              dense
              :filled="!editable"
              :disabled="!editable"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <h3>사업자등록증 사본</h3>
          <div v-if="editable === false">
            <v-chip
              v-if="user.businessImageId != undefined"
              label
              outlined
              color="primary"
              >등록완료</v-chip
            >
            <v-chip v-else>사업자등록증 사본을 등록해주세요.</v-chip>
          </div>

          <ImageFileInput
            v-else
            fileType="image"
            fileLabel="사업자등록증 사본"
            :fileSizeMax="5"
            @setImage="
              file => {
                user.businessImageFile = file;
              }
            "
          ></ImageFileInput>
        </v-col>
        <v-col cols="12" lg="6">
          <h3>통장 사본</h3>
          <div v-if="editable === false">
            <v-chip
              v-if="user.bankbookImageId != undefined && editable === false"
              label
              outlined
              color="primary"
              >등록완료</v-chip
            >
            <v-chip v-else>통장 사본을 등록해주세요.</v-chip>
          </div>
          <ImageFileInput
            v-else
            fileType="image"
            fileLabel="통장 사본"
            :fileSizeMax="5"
            @setImage="
              file => {
                user.bankbookImageFile = file;
              }
            "
          ></ImageFileInput>
        </v-col>
      </v-row>
      <hr style="margin-top: 20px; border: dashed 1px #ddd" />

      <div class="my-5 text-center">
        <v-btn v-if="!editable" color="secondary" @click="editable = true">
          수정하기
        </v-btn>
        <v-btn
          v-if="editable"
          color="secondary"
          @click="handleUpdateClick"
          :loading="loading"
          >적용하기</v-btn
        >
        <v-btn
          v-if="editable"
          color="secondary"
          outlined
          class="ml-2"
          @click="editable = false"
          >취소</v-btn
        >
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { BankList } from '@/config/charge';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import KakaoAddressForm from '@/components/KakaoAddressForm.vue';
import ImageFileInput from '@/components/ImageFileInput.vue';
import { updateProfileApi, getProfileApi } from '@/api/advertiser/UserAPI';
import { User } from '@/model/advertiser/User';
import { KakaoAddress } from '@/model/register/KakaoAddress';
import { convertFormData } from '@/utils/functions';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    KakaoAddressForm,
    ImageFileInput
  },
  data() {
    return {
      refKey: 'mypageRef',
      user: new User(),
      kakaoAddress: null,
      editable: false,
      item: {},
      loading: false
    };
  },
  computed: {
    isPasswordConfirmRequired() {
      const password = this.user?.password || '';
      return password.length > 0;
    },
    BankList() {
      return BankList;
    }
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    async handleUpdateClick() {
      this.loading = true;
      // 입력값의 유효성을 확인합니다.
      const valid = await this.$refs[this.refKey].validate();
      if (valid) {
        const option = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };

        const [error] = await updateProfileApi(
          convertFormData(this.user),
          option
        );
        if (error) {
          console.log(error);
          this.$axiosErrorAlert();
        } else {
          this.$router.go();
        }
      }
      this.loading = false;
    },
    async fetchProfile() {
      const [error, data] = await getProfileApi();
      if (error) {
        console.error(error);
      } else {
        const { user } = data;

        const newUser = new User();

        newUser.uid = user.uid;
        newUser.email = user.email;
        newUser.businessName = user.business_name;
        newUser.managerName = user.manager_name;
        newUser.managerTel = user.manager_tel;
        newUser.businessNum = user.business_num;
        newUser.businessPostcode = user.business_postcode;
        newUser.businessAddress = user.business_address;
        newUser.businessDetailAddress = user.business_detail_address;
        newUser.businessExtraAddress = user.business_extra_address;
        newUser.businessImageId = user.business_image_id;
        newUser.bankName = user.bank_name;
        newUser.bankAccountNum = user.bank_account_num;
        newUser.bankAccountHolder = user.bank_account_holder;
        newUser.bankbookImageId = user.bankbook_image_id;
        this.user = newUser;

        const newKakaoAddress = new KakaoAddress();

        newKakaoAddress.address = newUser.businessAddress;
        newKakaoAddress.postcode = newUser.businessPostcode;
        newKakaoAddress.detailAddress = newUser.businessDetailAddress;
        newKakaoAddress.extraAddress = newUser.businessExtraAddress;
        this.kakaoAddress = newKakaoAddress;
      }
    }
  }
};
</script>

<style></style>
