export class User {
    constructor(
        uid,
        email,
        password,
        passwordConfirmation,
        businessName,
        managerName,
        managerTel,
        businessNum,
        businessPostcode,
        businessAddress,
        businessDetailAddress,
        businessExtraAddress,
        businessImageId,
        businessImageFile,
        bankName,
        bankAccountNum,
        bankAccountHolder,
        bankbookImageId,
        bankbookImageFile
    ) {
        this.uid = uid;
        this.email = email;
        this.password = password;
        this.password_confirmation = passwordConfirmation;
        this.businessName = businessName;
        this.managerName = managerName;
        this.managerTel = managerTel;
        this.businessNum = businessNum;
        this.businessPostcode = businessPostcode;
        this.businessAddress = businessAddress;
        this.businessDetailAddress = businessDetailAddress;
        this.businessExtraAddress = businessExtraAddress;
        this.businessImageId = businessImageId;
        this.businessImageFile = businessImageFile;
        this.bankName = bankName;
        this.bankAccountNum = bankAccountNum;
        this.bankAccountHolder = bankAccountHolder;
        this.bankbookImageId = bankbookImageId;
        this.bankbookImageFile = bankbookImageFile;
    }
}

export class KakaoAddress {
    constructor(address, postcode, detailAddress, extraAddress) {
        this.address = address;
        this.postcode = postcode;
        this.detailAddress = detailAddress;
        this.extraAddress = extraAddress;
    }
}